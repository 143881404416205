import React, { useState, useEffect, useRef } from 'react';
import './AddNewsPage.css'
import SaveButton from '../../../Buttons/SaveButton';
import DiscardButton from '../../../Buttons/DiscardButton';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import {toast } from 'react-toastify';
import { API_BASE_URL, API_ENDPOINTS } from '../../../../config';

const getSuccessParamFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('success');
  };

function AddNewsPage(props){
    const [titleText, setTitleText] = useState('');
    const [descriptionText, setDescriptionText] = useState('');
    const [newsText, setNewsText] = useState('');
    const [image, setImage] = useState();
    const [imageSourceText, setImageSourceText] = useState('');
    const [imageFile, setImageFile] = useState();
    const [isAdmin, setAdmin] = useState();
    const [loading, setLoading] = useState(true);
    const [isChanged, setChanged] = useState(false);
    const showToastRef = useRef(false);

    useEffect(() => {
        const fetchData = async () => {
            await fetchAuth();
            setLoading(false);
            const successParam = getSuccessParamFromURL();
            if (successParam === '1' && !showToastRef.current) {
                toast.success('News is added', { position: 'top-center' });
                showToastRef.current = true;
            } else if (successParam === '0' && !showToastRef.current) {
                toast.error('Error uploading news', { position: 'top-center' });
                showToastRef.current = true;
            }
        }

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
            } catch (error) {
                console.error('Error fetching auth:', error);
            }
        }

        fetchData();


        const handleBeforeUnload = (event) => {
            if (isChanged) {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message;
            return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

      
    },[isChanged])

    const handleTitleTextChange = (event) => {
        setTitleText(event.target.value);
        setChanged(true);
    }

    const handleDescriptionTextChange = (event) => {
        setDescriptionText(event.target.value);
        setChanged(true);
    }

    const handleNewsTextChange = (event) => {
        setNewsText(event.target.value);
        setChanged(true);
    }

    const handleImageClick = () => {
        // Trigger the hidden file input when the image is clicked
        inputImageRef.current.click();
    };
    
    const handleImageChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
          setImage(URL.createObjectURL(file));
          setImageFile(file);
          setChanged(true);
        }
      };
      
  
    const handleImageSourceTextChange = (event) => {
        setImageSourceText(event.target.value);
        setChanged(true);
    }

    const   handleReload = () => {
        window.location.reload();
      };

    const inputImageRef = React.useRef(null);

    const handleSave = async () => {
        setChanged(false);
        if (imageFile) {
            try {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    const base64Data = reader.result.split(',')[1];
                    const newsData = {
                        "title": titleText,
                        "description": descriptionText,
                        "text": newsText,
                        "image": base64Data,
                        "imageSource": imageSourceText
                    };

                    try {
                        const url = API_BASE_URL + API_ENDPOINTS.addNews;
                        const response = await fetch(url, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(newsData)
                        });
    
                        const result = await response.json();
    
                        if (result.success) {
                            console.log('Data saved successfully!');
                            window.location.href = '/addNewsPage?success=1';
                        } else {
                            console.error('Failed to save data:', result.error);
                            window.alert('Data is not saved');
                        }
                    } catch (error) {
                        console.error('Error saving data:', error);
                    }
                };
    
                reader.readAsDataURL(imageFile);
            } catch (error) {
                console.error('Error reading image file:', error);
            }
        } else {
            console.error('No image file selected.');
            window.alert('Please select an image file');
        }
    };
    if (loading) {
        return <p>Loading...</p>
    }
    else if (isAdmin) {
                return ( 
                    <div className='page-main-div'>
                        <div className='page-content-div'>
                            <h2>
                                ADD NEWS
                            </h2>
                            <div className='card-div'>
                                <div className='card-content'>
                                    <h3 style={{fontSize:'2rem'}}>Title</h3>
                                    <textarea
                                        type='text'
                                        className='news-edit-title-input'
                                        value={titleText}
                                        onChange={handleTitleTextChange}
                                        placeholder='Add title...'
                                    />
                                    <h3 style={{fontSize:'2rem',paddingTop:'1rem'}}>Description</h3>
                                    <textarea
                                        type='text'
                                        className='news-edit-description-input'
                                        value={descriptionText}
                                        onChange={handleDescriptionTextChange}
                                        placeholder='Add description...'
                                    />
                                    <div style={{display:'flex',marginTop:'2.5rem'}}>
                                        <div className='opinion-image-and-title'>
                                            <input
                                                type='file'
                                                accept='image/*'
                                                ref={inputImageRef}
                                                style={{ display: 'none' }}
                                                onChange={handleImageChange}
                                            />
                                            {image ? (
                                                <img style={{height:'25rem', width:'auto', maxWidth:'100%'}} src={image} onClick={handleImageClick} alt="Selected Image" />
                                            ) : (
                                                <div
                                                    style={{
                                                        width: 'auto',
                                                        maxWidth: '100%',
                                                        height: '20rem',
                                                        border: '1px solid #ddd',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={handleImageClick}
                                                >
                                                    <span style={{ color: '#777' }}>Select Image</span>
                                                </div>
                                            )}
                                            <textarea
                                                type='text'
                                                className='news-edit-image-source-input'
                                                value={imageSourceText}
                                                onChange={handleImageSourceTextChange}
                                                placeholder='Add image source...'
                                            />
                                        </div>
                                    </div>
                                    <div className='opinion-text'>
                                        <textarea
                                            type='text'
                                            className='news-edit-news-text-input'
                                            value={newsText}
                                            onChange={handleNewsTextChange}
                                            placeholder='Add news text...'
                                        />
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <SaveButton onClick={handleSave}/>
                                        <DiscardButton onClick={handleReload}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return NotAllowedPage();
            }
        }

export default AddNewsPage