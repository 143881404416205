import React, { useState, useEffect, useRef } from 'react';
import './BioEditPage.css';
import SaveButton from '../../../Buttons/SaveButton';
import DiscardButton from '../../../Buttons/DiscardButton';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import {API_BASE_URL, API_ENDPOINTS} from '../../../../config.js';
import {toast } from 'react-toastify';

const getSuccessParamFromURL = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('success');
};

export default function BioEditPage() {
  const [jsonData, setJsonData] = useState({});
  const [imagesData, setImagesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAdmin, setAdmin] = useState();
  const [isChanged, setChanged] = useState(false);
  const showToastRef = useRef(false);
  

  const checkAuth = async () => {
    try {
      const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
      const response = await fetch(url,
      {
          method: 'GET',
          credentials: 'include',
      }
      );
      if (response.status == 200) {
          setAdmin(true)
          return true;
      } else {
          setAdmin(false);
          setLoading(false);       
          return false;
      }
    } catch (error) {
      console.error('Error fetching auth:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const check = await checkAuth();
      if (check) {
        try {
          const url = API_BASE_URL + API_ENDPOINTS.getBio;
          const response = await fetch(url);
          const data = await response.json();
          setJsonData(data);
          setLoading(false);
          const successParam = getSuccessParamFromURL();

          if (successParam === '1' && !showToastRef.current) {
            toast.success('Bio is updated', { position: 'top-center' });
            showToastRef.current = true;
          } else if (successParam === '0' && !showToastRef.current) {
            toast.error('Error updating Bio', { position: 'top-center' });
            showToastRef.current = true;
          }

        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      }
    };
    
    fetchData();

    const handleBeforeUnload = (event) => {
      if (isChanged) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };


    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [isChanged]); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount

  
  const handleTextChange = (sectionTitle, newText) => {
    setJsonData((prevData) => ({
      ...prevData,
      [sectionTitle]: newText,
    }));
    setChanged(true);
  };

  const handleImagesChange = (imagePath, newImage) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result.split(',')[1];
      setImagesData((prevData) => ({
        ...prevData,
        [imagePath]: base64Data,
      }));
    };
    reader.readAsDataURL(newImage);
    setChanged(true);
  };
  


  const handleSave = async () => {
    setChanged(false);
    const allBioData = {...jsonData,...imagesData}
    try {
      const url = API_BASE_URL + API_ENDPOINTS.bioUpdate;
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(allBioData),
      });

      const result = await response.json();
  
      if (result.success) {
        window.location.href= '/bioEditPage?success=1'
      } else {
        console.error('Failed to save data:', result.error);
        window.location.href= '/bioEditPage?success=0'
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };




  const  handleReload = () => {
    window.location.reload();
  };


  if (loading) {
    return <p>Loading...</p>; // You can replace this with a loading spinner or any other loading indicator
  }

  if (!isAdmin) {
    return NotAllowedPage();
  }
  
  if (isAdmin) {
    return (
      <div>
        <div className="bio-page-main-div">
          <div className="bio-page-content-div">
            <h2>EDIT BIOGRAPHY</h2>
            <SectionCard
              title="Academic Career"
              image={process.env.PUBLIC_URL + jsonData['Academic Photo']}
              initialText={jsonData['Academic Career']}
              onTextChange={(newText) => handleTextChange('Academic Career', newText)}
              onImageChange={(newImage) => handleImagesChange('bio-academical-career-img.jpeg',newImage)}
            />
            <SectionCard
              title="Journalist Career"
              image={process.env.PUBLIC_URL + jsonData['Journalist Photo']}
              initialText={jsonData['Journalist Career']}
              onTextChange={(newText) => handleTextChange('Journalist Career', newText)}
              onImageChange={(newImage) => handleImagesChange('bio-journalist-career-img.jpeg',newImage)}
            />

            <div style={{ display: 'flex' }}>
              <SaveButton onClick={handleSave} />
              <DiscardButton onClick={handleReload} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function SectionCard(props){
    const [inputText, setInputText] = useState(props.initialText);
    const [image, setImage] = useState(props.image);

    const handleInputChange = (event) => {
        const newText = event.target.value;
        setInputText(newText);
        props.onTextChange(newText); // Call the callback function with the updated text
    };



  const handleImageClick = () => {
    // Trigger the hidden file input when the image is clicked
    inputImageRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setImage(URL.createObjectURL(file));
      props.onImageChange(file);
    }
  };

    const inputImageRef = React.useRef(null);

    return (
        <div className='card-div'>
            <div className='bio-card-content'>
                <h2>{props.title}</h2>
                <div className='text-and-image-div'>
                    <textarea
                        type='text'
                        className='bio-edit-text-input'
                        value={inputText}
                        onChange={handleInputChange}
                        placeholder='Enter text here...'
                    />
                    <input
                        type='file'
                        accept='image/*'
                        ref={inputImageRef}
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                    <img
                        src={image}
                        onClick={handleImageClick}
                        className='bio-image'
                        style={{opacity:''}}
                        alt={props.title}
                    />
                </div>
            </div>
        </div>
    )
}