import React from 'react';
import './MainPage.css';
import boraProtest from '../../../bora-bayraktar-images/bora-protest.jpeg';
import boraAndBald from '../../../bora-bayraktar-images/bora-and-bald-man.jpeg';
import boraAndBlm from '../../../bora-bayraktar-images/bora-blm.jpeg';

export default function LatestOpinionsContent(props) {
    const { latestOpinions } = props;
    const renderOpinions = () => {
        if (!latestOpinions || latestOpinions.length === 0) {
          return null;
        }
    
        const [firstOpinion, ...restOpinions] = latestOpinions;
    
        return (
          <div className="latest-opinions-section-div" style={{ justifyContent: 'space-around' }}>
            <BigBox image={firstOpinion.imagePath} title={firstOpinion.title} id={firstOpinion.id} subject={firstOpinion.subject}/>
            {restOpinions.map((opinion, index) => (
              <SmallBox key={index} image={opinion.imagePath} title={opinion.title} id={opinion.id} subject={opinion.subject}/>
            ))}
          </div>
        );
      };

      return (
        <div style={{ backgroundColor: 'rgb(233, 233, 233)', width: '100%' }}>
          <div className="latest-opinions-content-div">
            <h2>LATEST OPINIONS</h2>
            {renderOpinions()}
          </div>
        </div>
      );
}

function BigBox(props){
    const handleClick = () => {
        window.location.pathname = "/opinionEntry/" + props.subject+ "/"+props.id;
    }

   return ( 
        <div className='big-box-content-div' onClick={handleClick}>
            <img src={props.image} alt={props.title}/>
            <h2>{props.title}</h2>
        </div>
   )
}

function SmallBox(props){
    const handleClick = () => {
      window.location.pathname = "/opinionEntry/" + props.subject+ "/"+props.id;
  }
    return (
      
        <div className='small-box-content-div' onClick={handleClick}>
            <img src={props.image} alt={props.title}/>
            <h2>{props.title}</h2>
        </div>
    )
}
