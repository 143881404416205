import React, {useState, useEffect, useRef} from 'react';
import VideoComboBoxComponent from './VideoComboBoxComponent';
import SaveButton from '../../../Buttons/SaveButton';
import DiscardButton from '../../../Buttons/DiscardButton';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import {toast } from 'react-toastify';
import { API_BASE_URL, API_ENDPOINTS } from '../../../../config';


const getSuccessParamFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('success');
  };

export default function AddVideoPage() {
    const [section, setSection] = useState('');
    const [descriptionText, setDescriptionText] = useState('');
    const [urlText, setUrlText] = useState('');
    const [isAdmin, setAdmin] = useState();
    const [loading, setLoading] = useState(true);
    const [isChanged, setChanged] = useState(false);
    const showToastRef = useRef(false);

    useEffect(() => {
        const fetchData = async () => {
            await fetchAuth();
            setLoading(false);
            const successParam = getSuccessParamFromURL();
            if (successParam === '1' && !showToastRef.current) {
                toast.success('Video is added', { position: 'top-center' });
                showToastRef.current = true;
            } else if (successParam === '0' && !showToastRef.current) {
                toast.error('Error uploading video', { position: 'top-center' });
                showToastRef.current = true;
            }

        }

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching auth:', error);
                setLoading(false);
            }
        }
        fetchData();

        const handleBeforeUnload = (event) => {
            if (isChanged) {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message;
            return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };


    },[isChanged])


    const handleComboBoxChange = (selectedValue) => {
        setSection(selectedValue);
        setChanged(true);
    };


    const handleDescriptionTextChange = (event) => {
        setDescriptionText(event.target.value);
        setChanged(true);
    }

    const handleUrlTextChange = (event) => {
        setUrlText(event.target.value);
        setChanged(true);
    }

    const  handleReload = () => {
        window.location.reload();
      };


    function extractedVideoId(url) {
        // Regular expression to match YouTube video ID in different URL formats
        var regex = /[?&]v=([^#&?]*).*/;
        var match = url.match(regex);
      
        // Check if there's a match and return the video ID
        if (match && match[1]) {
          return match[1];
        } else {
          // If no match is found, or if the URL format is not supported, you can handle it accordingly
          console.error('Invalid YouTube URL or unsupported format');
          return null;
        }
    }

      
    const handleSave = async () => {
        console.log('clicked');
        setChanged(false);
        if (urlText != '' & descriptionText != '') {
            try {
                const videoId = extractedVideoId(urlText);
                const videoJsonData = {
                    'section': section,
                    'title':'',
                    'description':descriptionText,
                    'videoId': videoId
                };
                console.log(videoJsonData);
                try {
                    const url = API_BASE_URL + API_ENDPOINTS.addVideo;
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(videoJsonData)
                    });

                    const result = await response.json();

                    if (result.success) {
                        console.log('Data saved successfully!');
                        window.location.href = '/addVideo?success=1';
                    } else {
                        console.error('Failed to save data:', result.error);
                        window.location.href = '/addVideo?success=0';
                    }
                } catch (error) {
                    console.error('Error saving data:', error);
                }

            } catch (error) {
                console.error('Something went wrong:', error);
            }
        } else {
            console.error('Fill all the fields');
        }
    };
    if (loading) {
        return (<p>Loading...</p>)
    }
    else if (isAdmin) {
        return (
            <div className='tv-page-main-div'>
                <div className='tv-page-content-div'>
                    <h2>ADD VIDEO</h2>
                    <div className='card-div'>
                        <div className='card-content'>
                            <h3 style={{fontSize:'2rem',marginBottom:'1rem'}}>Section</h3>
                            <VideoComboBoxComponent onChange={handleComboBoxChange}/>
                            <h3 style={{fontSize:'2rem',marginBottom:'1rem'}}>Description</h3>
                            <textarea
                                    type='text'
                                    className='news-edit-description-input'
                                    value={descriptionText}
                                    onChange={handleDescriptionTextChange}
                                    placeholder='Add description...'
                            />
                            <h3 style={{fontSize:'2rem',marginBottom:'1rem',marginTop:'1rem'}}>YouTube URL</h3>
                            <textarea
                                    type='text'
                                    className='news-edit-image-source-input'
                                    value={urlText}
                                    onChange={handleUrlTextChange}
                                    placeholder='Enter the YouTube URL of the video...'
                                />
                            <div style={{display:'flex',marginTop:'1rem'}}>
                                <SaveButton onClick={handleSave}/>
                                <DiscardButton onClick={handleReload}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    } else {
        return NotAllowedPage();
    }
}