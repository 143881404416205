import React, { useEffect, useState } from "react";
import './PublishingPage.css';
import { API_BASE_URL, API_ENDPOINTS } from "../../../config";

export default function PublishingsPage() {

    const [publishingsData, setPublishingsData] = useState({});
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.getPDFs;
                const response = await fetch(url);
                const responseData = await response.json();
                if (Array.isArray(responseData.publishings)) {
                    setPublishingsData(responseData.publishings);
                } else {
                    console.error('Invalid data received from the API: ', responseData);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);;
                setLoading(false);
            }
        };
        fetchData();
     },[]);

    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <div className="page-main-div">
            <div className="page-content-div">
                <h2>PUBLICATIONS</h2>
                <div className="card-div">
                    <div className="card-content">
                        {
                            publishingsData.map((pub) => <PublishingComponent id={pub.id} thumbnailPath={pub.thumbnailPath} title={pub.title} description={pub.description} date={pub.date}/>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )



    function PublishingComponent(props) {
        const handleClick = () => {
            const id = props.id;
            const pdfUrl = process.env.PUBLIC_URL+ "/data/pdfs/" + id + '.pdf';
            window.open(pdfUrl, '_blank');
        } 
        // Create a new Date object with milliseconds since epoch
        const date = new Date(props.date);

        // Get individual components of the date
        const month = date.getMonth() + 1; // Months are zero-based, so add 1
        const day = date.getDate();
        const year = date.getFullYear();

        // Format the date as MM/DD/YYYY
        const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

        return (
            <div className='publishing-block' onClick={handleClick}>
                <div className='publishing-component'>
                    <img src={process.env.PUBLIC_URL + props.thumbnailPath}/>
                    <div>
                        <h2>{props.title}</h2>
                        <h4>{formattedDate}</h4>
                        <h3>{props.description}</h3>
                    </div>
                </div>
                <div className='divider'/>
            </div>
        )
    }
}