import React from "react";
import './style.css';

export default function SectionBox(props) {
    const navigateFunction = () => {
        window.location.href = props.page;
    }
    return (
        <div className="admin-section-box" onClick={navigateFunction}>
            <h2>{props.title}</h2>
        </div>
    )
}