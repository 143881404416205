import React from 'react';
import './ContactBanner.css'
import facebookIcon from '../../assets/social-media-icons/facebook.svg'
import instagramIcon from '../../assets/social-media-icons/instagram.svg'
import linkedinIcon from '../../assets/social-media-icons/linkedin.svg'
import xIcon from '../../assets/social-media-icons/x.svg'

export default function ContactBannerComponent() {
    return (
        <div className='contact-banner-div'>
            <h2>Follow Bora Bayraktar</h2>
            <div className='icons-div'>
                <a href="https://www.facebook.com">
                    <img src={facebookIcon} alt="Facebook"/>
                </a>
                <a href="https://www.instagram.com/borabay/">
                    <img src={instagramIcon} alt="Instagram"/>
                </a>
                <a href="https://tr.linkedin.com/in/bora-bayraktar-9436073a">
                    <img src={linkedinIcon} alt="LinkedIn"/>
                </a>
                <a href="https://twitter.com/Bora_Bayraktar">
                    <img src={xIcon} alt="X Icon"/>
                </a>
            </div>
            <h3>© 2023 Bora Bayraktar. All Rights Reserved.</h3>
            <h3 style={{color:'grey'}}>Powered by Nusret Ali Kızılaslan</h3>
        </div>
    )
}
