import React, {useContext, useState} from "react";
import { API_BASE_URL,API_ENDPOINTS } from "../../../config";

export default function AdminLogin() {

    const [username,setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [warning, setWarning] = useState('');


    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }


    const handleLogin = async ()   => {
        try {
            if (username != '' && password != '') { 
                const loginInfo = {
                    'username':username,
                    'password':password
                }
                const url = API_BASE_URL + API_ENDPOINTS.login;
                const response = await fetch(url, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(loginInfo)
                });

                if (response.status == 200) {
                    window.location.href = '/admin';
                } else {
                    setWarning('Username or password is not valid');
                }
            } else {
                setWarning('Please set all the fields');
            }
        } catch (error) {

        }
    }

        
    return (
        <div className="page-main-div">
            <div className="page-content-div">
                <h2>
                    ADMIN LOGIN
                </h2>
                <div className="card-div">
                    <div className="card-content">
                        <div>
                            <h3 style={{ fontSize: '2rem', marginBottom: '0.5rem' }}>Username</h3>
                            <input type="text" value={username} onChange={handleUsernameChange} style={{ height: '2.5rem', width: '20rem', paddingLeft: '10px' }} />
                        </div>
                        <div>
                            <h3 style={{ fontSize: '2rem', marginBottom: '0.5rem', marginTop: '3rem' }}>Password</h3>
                            <input type="password" value={password} onChange={handlePasswordChange} style={{ height: '2.5rem', width: '20rem', paddingLeft: '10px' }} />
                        </div>
                        <h4 style={{fontSize:'1rem', color:'red',marginTop:'1rem'}}>{warning}</h4>
                        <div>
                            <button className="login-button" onClick={handleLogin}>LOGIN</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
