import React from 'react';
import './NotFoundPage.css';

export default function NotFoundPage() {

    const handleClick = () => {
        window.location.pathname = "/"
    }

    const buttonStyle = {
        backgroundColor: 'transparent',
        color: 'black',
        border: '1px solid black',
        paddingTop: '1.5%',
        paddingBottom: '1.5%',
        paddingLeft: '3%',
        paddingRight: '3%',
        alignItems: 'flex-start', // Fixed property assignment
        fontSize: '16px', // Added quotes around '16px'
        fontWeight: 'normal',
        cursor: 'pointer', // Added quotes around 'pointer'
        transition: 'background-color 0.3s, color 0.3s', // Added quotes around property values
        ':hover': {
            backgroundColor: 'black',
            color: 'white',
            opacity: 1, // Decrease opacity on hover
          },
    };
      
    
    return (
        <div className='page-main-div'>
        <div className='page-content-div'>
            <h2>PAGE NOT FOUND</h2>
            <button className='return-main-menu-button' onClick={handleClick}>
                RETURN TO MAIN PAGE
            </button>
        </div>
    </div>
    )
}