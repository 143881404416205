import React, { useRef, useEffect, useState } from 'react';
import {FaTrash } from 'react-icons/fa';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import AddButton from '../../../Buttons/AddButton';
import { API_BASE_URL, API_ENDPOINTS } from '../../../../config';

function EditPhotosPage() {

  const [loading,setLoading] = useState(true);
  const [photos, setPhotos] = useState({});
  const [isAdmin, setAdmin] = useState();

 
  const handleAddButtonAction = () => {
    window.location.href = '/addPhoto';
  }

  useEffect(() => {
      const fetchData = async () => {
          try {
              await fetchAuth();
              const url = API_BASE_URL + API_ENDPOINTS.getPhotos;
              const response = await fetch(url);
              const responseData = await response.json();
              console.log(responseData)
              setPhotos(responseData);
              setLoading(false);
          } catch (error) {
              console.error('Error fetching data:', error);;
              setLoading(false);
          }
      };

      const fetchAuth = async () => {
        try {
            const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
            const response = await fetch(url,
            {
                method: 'GET',
                credentials: 'include',
            }
            );
            if (response.status == 200) {
                setAdmin(true)
            } else {
                
                setAdmin(false);
            }
        } catch (error) {
            console.error('Error fetching auth:', error);
        }
    }

      fetchData();
  },[isAdmin]);


    if (loading) {
      return <p>Loading...</p>
    }
    else if (isAdmin) {
      return (
        <div className="page-main-div">
          <div className="page-content-div">
            <h2>EDIT PHOTOS</h2>
            <AddButton onClick={handleAddButtonAction}/>
            <div style={{margin:'2rem'}}>                
            </div>
            <div className="card-div">
              <div className="card-content">
                <h2>With Leaders</h2>
                <div className="photos-container">
                {photos['photos']
                  .filter((photo) => photo['section'] === 'with leader')
                  .map((photo, index) => (
                    <div key={index}>
                      <PhotoBox photo={photo} />
                    </div>
                ))}
                </div>
              </div>
            </div>
            <div className="card-div">
              <div className="card-content">
              <h2>On field</h2>
                <div className="photos-container">
                  {photos['photos']
                    .filter((photo) => photo['section'] === 'on field')
                    .map((photo, index) => (
                      <div key={index}>
                        <PhotoBox photo={photo} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="card-div">
              <div className="card-content">
                <h2>Interviews</h2>
                <div className="photos-container">
                  {photos['photos']
                    .filter((photo) => photo['section'] === 'interviews')
                    .map((photo, index) => (
                      <div key={index}>
                        <PhotoBox photo={photo} />
                      </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="card-div">
              <div className="card-content">
              <h2>Memorables</h2>
                <div className="photos-container">
                  {photos['photos']
                    .filter((photo) => photo['section'] === 'memorables')
                    .map((photo, index) => (
                      <div key={index}>
                        <PhotoBox photo={photo} />
                      </div>
                  ))}
                </div>
              </div>
              </div>
            </div>
        </div>
      );
    } else {
        return NotAllowedPage();
    }
  }
  
  export default EditPhotosPage;

  function PhotoBox(props) {
    
    const imageSource = process.env.PUBLIC_URL + props.photo['photoPath'];
  
    // Create a new Date object with milliseconds since epoch
    const date = new Date(props.photo['date']);

    // Get individual components of the date
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date as MM/DD/YYYY
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;


    const handleDelete = async () => {
        const shouldDelete = window.confirm('Are you sure you want to delete this entry?');
    
        if (shouldDelete) {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.deletePhoto + '/' + props.photo.id;
                const response = await fetch(url, {
                    method: 'DELETE',
                    credentials: 'include',
                });

                console.log(response);
            } catch (error) {
                console.error('Error fetching auth:', error);
            }
        }
    };


    return (
      <div className="photo-box-container">
        <img
          src={imageSource}
          alt={`Image ${imageSource}`}
        />
        <h4 style={{color:'grey', marginBottom:'0.5rem'}}>{formattedDate}</h4>
        <h3>{props.photo['title']}</h3>
        <button className="delete-button" onClick={() => handleDelete()} style={{ background: 'none', border: 'none',  fontSize:'1.5rem', color:'red'}}>
                <FaTrash />
        </button>
      </div>
    );
  }
  