import React, { useState, useEffect } from 'react';
import {API_BASE_URL, API_ENDPOINTS} from '../../../config.js';
import './BioPage.css';

export default function BioPage() {
    const [jsonData, setJsonData] = useState({});
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const url = API_BASE_URL + API_ENDPOINTS.getBio;  
          const response = await fetch(url);
          const data = await response.json();
          setJsonData(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, []); 

    
    if (loading){
        return (
            <div>
                Loading...
            </div>
        )
    } 
    else {
        return (
            <div className='bio-page-main-div'>
                <div className='bio-page-content-div'>
                    <h2>BIOGRAPHY</h2>
                    <SectionCard
                        title="Journalist"
                        image={process.env.PUBLIC_URL + jsonData['Journalist Photo']}
                        text={jsonData['Journalist Career']}
                    />
                    <SectionCard
                        title="Academic"
                        image={process.env.PUBLIC_URL + jsonData['Academic Photo']}
                        text={jsonData['Academic Career']}
                    />
                </div>
            </div>
        )
    }
}

function SectionCard(props){
    return (
        <div className='card-div'>
            <div className='bio-card-content'>
                <h2>{props.title}</h2>
                <div className='text-and-image-div'>
                    <p>{props.text}</p>
                    <img src={props.image} />
                </div>
            </div>
        </div>
    )
}