import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAdd} from '@fortawesome/free-solid-svg-icons';

function AddButton({onClick}) {

    return (
        <button className='edit-save-button' onClick={onClick} >
        <FontAwesomeIcon icon={faAdd} style={{ marginRight: '5px' }} />
            Add
        </button> )
}

export default AddButton