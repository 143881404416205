import React, { useState, useEffect } from 'react';
import boraProtest from '../../../bora-bayraktar-images/bora-protest.jpeg';
import './OpinionEntryPage.css'
import { API_BASE_URL, API_ENDPOINTS } from '../../../config';


function OpinionEntryPage(){
    const [jsonData, setJsonData] = useState({});
    const [loading, setLoading] = useState(true);
    const [relatedOpinions, setRelatedOpinions] = useState([]);
    const [subject, setSubject] = useState('');

    let payload = {};

    useEffect(() => {
        const fetchData = async () => {
        const url = window.location.href;
        console.log(url);
        const segments = url.split('/').filter(segment => segment.trim() !== '');

        console.log(segments);
        payload = {
            'id':segments[segments.length - 1],
            'subject':decodeURIComponent(segments[segments.length - 2])
        };

        setSubject(decodeURIComponent(segments[segments.length - 2]));

        console.log(payload);
      
    try {
          const url = API_BASE_URL + API_ENDPOINTS.getOpinionsById;
          const response = await fetch(url,{
              method: 'POST',
              credentials: 'include',
              headers: {
                  'Content-Type':'application/json'
              },
              body: JSON.stringify(payload)
          });

          const data = await response.json();
          setJsonData(data);

          const urlRelatedOpinions = API_BASE_URL + API_ENDPOINTS.getRelatedOpinionsSideOnes;
          const relatedOpinionsResponse = await fetch(urlRelatedOpinions,{
            method: 'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(payload)
        });
            const dataRelatedOpinions = await relatedOpinionsResponse.json();
            setRelatedOpinions(dataRelatedOpinions);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
      };
  
      fetchData();
    }, []); 


    const renderedRelatedOpinions = relatedOpinions.map((opinion,index) => (
        <div>
            <RelatedOpinion key={index} id={opinion.id} title={opinion.title} description={opinion.description}/>
            <div className='divider' style={{width:'22vw'}}/>
        </div>
    ));


    const date = new Date(jsonData.date);

    // Get individual components of the date
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date as MM/DD/YYYY
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

    if (loading) {
        return (
            <div>
                Loading...
            </div>
        )
    } else {

        return ( 
            <div className='page-main-div'>
                <div className='page-content-div'>
                    <div className='card-div'>
                        <div className='card-content'>
                            <h4 style={{marginBottom:'1rem',color:'grey', fontWeight:'bold'}}>{formattedDate + ' • ' + subject}</h4>
                            <h2>{jsonData['title']}</h2>
                            <h3 className='opinion-subtitle'>{jsonData['description']}</h3>
                            <div style={{display:'flex',marginTop:'2.5rem'}}>
                                <div className='opinion-image-and-title'>
                                    <img src={process.env.PUBLIC_URL + jsonData['imagePath']}/>
                                    <h3>{jsonData.imageSource}</h3>
                                    <div className='opinion-text'>
                                        <p>{jsonData['text']}</p>
                                    </div>
                                </div>
                                <div className='div-related-opinions'>
                                    <h3>RELATED</h3>
                                    <div className='divider' style={{width:'22vw'}}/>
                                    {renderedRelatedOpinions}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function RelatedOpinion(props){
        const onClickAction = () => {
            window.location.href = "/opinionEntry/" + subject + "/" + props.id;
        }
    
        return (
            <div className= 'related-opinion' onClick={onClickAction}>
                    <h2 style={{fontSize:'1.5rem'}}>
                        {props.title}
                    </h2>
                    <h3 style={{fontSize:'1rem',fontWeight:'250',paddingTop:'2%'}}>
                        {props.description}
                    </h3>
            </div>
        )
    }
}

export default OpinionEntryPage