import React from 'react';
import './MainPage.css';

export default function Slide(props) {

  const handleClick = () => {
    window.location.pathname = "/newsEntry/" + props.id;
  }

  return (
    <div
      key={props.key}
      className="slide"
      style={{
        transform: `translateX(-${props.currentPhotoIndex * 100}%)`,
      }}
    >
      <div className="slide-content" onClick={handleClick}>
          <img src={process.env.PUBLIC_URL + props.imagePath}/>
          <div className='text-and-buttons-div'>
            <div className="text-content">
              <h2>{props.title}</h2>
              <p>{props.description}</p>
            </div>
          </div>
      </div>

    </div>
   
  );
}