import React, { Component, useState } from 'react';
import Slide from './Slide';
import './MainPage.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

class SlideshowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPhotoIndex: 0,
      slides: props.slides,
      imageOpacity: 1,
      activeDot: 0,
    };
  }


  componentDidMount() {
    this.timer = setInterval(this.nextPhoto, 7000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  nextPhoto = () => {
    this.setState((prevState) => ({
      currentPhotoIndex: (prevState.currentPhotoIndex + 1) % this.state.slides.length,
      imageOpacity: 1,
      activeDot: (prevState.activeDot + 1) % this.state.slides.length,
    }));
  };

  prevPhoto = () => {
    this.setState((prevState) => ({
      currentPhotoIndex:
        (prevState.currentPhotoIndex - 1 + this.state.slides.length) %
        this.state.slides.length,
      imageOpacity: 1,
      activeDot:
        (prevState.activeDot - 1 + this.state.slides.length) % this.state.slides.length,
    }));
  };

  

  handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowRight':
        this.nextPhoto();
        break;
      case 'ArrowLeft':
        this.prevPhoto();
        break;
      default:
        break;
    }
  };

  render() {
    const { currentPhotoIndex, slides, activeDot } = this.state;
    return (
      
        <div tabIndex="0" onKeyDown={this.handleKeyDown} style={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              marginTop: '40px',
              position: 'absolute',
              zIndex: '1',
              marginTop: '15%',
              width: '100%'
            }}
          >
          </div>
          <div className="slideshow-container">
            <div style={{position: 'relative'}}>
              <div className="button-container">
                <button onClick={this.prevPhoto} className="icon-button">
                  <FaArrowLeft />
                </button>
                <button onClick={this.nextPhoto} className="icon-button">
                  <FaArrowRight />
                </button>
              </div>
              <div className="slideshow">
                {slides.map((slide, index) => (
                  <Slide
                    key={index}
                    currentPhotoIndex={currentPhotoIndex}
                    style={{ transform: `translateX(-${currentPhotoIndex * 100}%)` }}
                    {...slide}
                  />
                ))}
                </div>
              </div>
            </div>
          </div>
    );
  }
}

export default SlideshowComponent;
