import React, {useState, useEffect, useRef} from 'react';
import SaveButton from '../../../Buttons/SaveButton';
import DiscardButton from '../../../Buttons/DiscardButton';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import {toast } from 'react-toastify';
import { API_BASE_URL, API_ENDPOINTS } from '../../../../config';


const getSuccessParamFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('success');
  };


export default function AddPublishingPage() {
    const [titleText, setTitleText] = useState('');
    const [descriptionText, setDescriptionText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isAdmin, setAdmin] = useState();
    const [loading, setLoading] = useState(true);
    const [isChanged, setChanged] = useState(false);
    const showToastRef = useRef(false);


    useEffect(() => {
        const fetchData = async () => {
            await fetchAuth();
            setLoading(false);
            const successParam = getSuccessParamFromURL();
            if (successParam === '1' && !showToastRef.current) {
                toast.success('Publishing is added', { position: 'top-center' });
                showToastRef.current = true;
            } else if (successParam === '0' && !showToastRef.current) {
                toast.error('Error uploading publishing', { position: 'top-center' });
                showToastRef.current = true;
            }
        }

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching auth:', error);
                setLoading(false);
            }
        }
        fetchData();


        const handleBeforeUnload = (event) => {
            if (isChanged) {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message;
            return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    },[isChanged])



    const handleFileChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            setSelectedFile(file);
            console.log(file);
        }
    };


    const handleTitleTextChange = (event) => {
        setTitleText(event.target.value);
        setChanged(true);
    }

    const handleDescriptionTextChange = (event) => {
        setDescriptionText(event.target.value);
        setChanged(true);
    }
    
    const  handleReload = () => {
        window.location.reload();
      };

    const handleSave = async () => {
        setChanged(false);
        try {
            const reader = new FileReader();
            reader.onloadend = async () => {
                if (reader.result instanceof ArrayBuffer) {
                    const uint8Array = new Uint8Array(reader.result);

                    // Convert the Uint8Array to a Blob
                    const blob = new Blob([uint8Array], { type: 'application/pdf' });

                    const saveData = {
                        'title':titleText,
                        'description':descriptionText
                    };
                    
                    try {
                        const formData = new FormData();
                        formData.append('pdfFile', blob, 'file.pdf');
                        formData.append('jsonData',JSON.stringify(saveData));
                        const url = API_BASE_URL + API_ENDPOINTS.pdfSave;
                        const response = await fetch(url, {
                            method: 'POST', 
                            body: formData,
                        });

                        // Assuming result is defined somewhere in your code
                        const result = await response.json();
                        
                        if (result.success) {
                            const pdfId = result.pdfId;
                            console.log('File uploaded successfully. PDF ID:', pdfId);
                            window.location.href = '/addPublishing?success=1';
                        } else {
                            console.error('File upload failed');
                        }
                    } catch (error) {
                        console.error('Error during file upload:', error);
                    }
                }
            };

            // Read the file as ArrayBuffer
            reader.readAsArrayBuffer(selectedFile);


        } catch (error) {
            console.error('Error:', error);
        } 
    }
    if (loading) {
        return <p>Loading...</p>
    }
    else if (isAdmin) {
        return(
        <div className="page-main-div">
            <div className="page-content-div">
                <h2>
                    ADD PUBLISHING
                </h2>
                <div className="card-div">
                    <div className="card-content">
                    <input
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            style={{ marginBottom: '1rem' }}
                            />
                            <h3 style={{fontSize:'2rem',marginBottom:'0.5rem'}}>Title</h3>
                            <textarea
                                        type='text'
                                        className='news-edit-description-input'
                                        value={titleText}
                                        onChange={handleTitleTextChange}
                                        placeholder='Add title...'
                                />
                            <h3 style={{fontSize:'2rem',marginBottom:'0.5rem'}}>Description</h3>
                                <textarea
                                        type='text'
                                        className='news-edit-description-input'
                                        value={descriptionText}
                                        onChange={handleDescriptionTextChange}
                                        placeholder='Add description...'
                                />
                                <div style={{display:'flex',marginTop:'1rem'}}>
                                    <SaveButton onClick={handleSave}/>
                                    <DiscardButton onClick={handleReload}/>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return NotAllowedPage();
    }
}