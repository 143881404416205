import React, { useState } from 'react';
import './NavigationBar.css';
import MobileNavigationSidebar from '../MobileNavigationSidebar/MobileNavigationSidebar';
import { FaBars } from 'react-icons/fa';


export default function NavigationBar() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const navStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    borderBottom: '0.5px solid grey',
    zIndex: 100,
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <div style={{ position: 'relative' }}>
      <nav style={navStyle}>
        <div className="nav-container">
          <a href="/" className="site-title">
            BORA BAYRAKTAR
          </a>
          <div className="right-buttons">
            {/* Add a button to toggle the sidebar visibility */}
            <a href='/news'>NEWS</a>
            <a href='/bio'>BIO</a>
            <a href='/tv'>TV WORK</a>
            <a href='/opinions'>OPINION</a>
            <a href='/publishings'>PUBLICATIONS</a>
            <a href='/photos'>PHOTOS</a>
            <button onClick={toggleSidebar} className='sidebar-button'>
              <FaBars/>   
            </button>
            <a>|</a>
            <a className="lan-button">TR</a>
          </div>
        </div>
      </nav>
      {/* Pass the visibility state and hide function to the sidebar */}
      <MobileNavigationSidebar visible={isSidebarVisible} />
    </div>
  );
}
