const API_BASE_URL = "https://borabayraktar.com/api";
//const API_BASE_URL = 'http://localhost:8000';
const API_ENDPOINTS = {
    getBio: '/getBio',
    getNews: '/getNews',
    getLatestNews: '/getLatestNews',
    getLatestOpinions: '/getLatestOpinions', 
    bioUpdate: '/bioUpdate',
    login: '/login',
    checkAuthorization: '/check-authorization',
    getNewsById: '/getNewsById',
    getLatestNewsSideOnes: '/getLatestNewsSideOnes',
    addNews: '/addNews',
    updateNews: '/updateNews',
    deleteNews: '/deleteNews',
    getOpinions: '/getOpinions',
    getOpinionsById: '/getOpinionsById',
    getRelatedOpinionsSideOnes: '/getRelatedOpinionsSideOnes',
    addOpinion: '/addOpinion',
    updateOpinion: '/updateOpinion',
    deleteOpinion: '/deleteOpinion',
    getVideos: '/getVideos',
    addVideo: '/addVideo',
    deleteVideo: '/deleteVideo',
    getPDFs: '/getPDFs',
    pdfSave: '/pdfSave',
    deletePDF: '/deletePDF',
    getPhotos: '/getPhotos',
    addPhoto: '/addPhoto',
    deletePhoto: '/deletePhoto',
    
}

export {API_BASE_URL, API_ENDPOINTS};