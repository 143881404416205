import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';

function MobileNavigationSidebar({ visible }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sidebarStyle = {
    display: windowWidth < 1150  ? 'flex' : 'none',
    position: 'fixed',
    right: visible ? 0 : '-100%',
    zIndex: 200,
    height: '100vh',
    color: 'white',
    backgroundColor: 'white',
    marginTop: '4rem',
    transition: 'right 0.3s ease-in-out', // Add a transition effect
  };

  const handleNavigation = (path) => {
    window.location.pathname = path;
  };

  return (
    <Sidebar className="sidebar" style={sidebarStyle}>
      <Menu
        style={{
          width: '60vw',
          backgroundColor: 'white',
        }}
        menuItemStyles={{
          button: {
            [`&.active`]: {
              backgroundColor: 'white',
              color: '#b6c8d9',
            },
          },
        }}
      >
        <MenuItem
         style={{ backgroundColor: 'white', width: '100%' }}
         onClick={() => handleNavigation('/news')}
         >
          NEWS
         </MenuItem>
        <MenuItem
          style={{ backgroundColor: 'white', width: '100%' }}
          onClick={() => handleNavigation('/bio')}
        >
          BIO
        </MenuItem>
        <MenuItem 
            style={{ backgroundColor: 'white', width: '100%' }}
            onClick={() => handleNavigation('/opinions')}
        >
            OPINION
        </MenuItem>
        <MenuItem 
            style={{ backgroundColor: 'white', width: '100%' }}
            onClick={() => handleNavigation('/tv')}
        >
        TV
        </MenuItem>
        <MenuItem 
          style={{ backgroundColor: 'white', width: '100%' }}
          onClick={() => handleNavigation('/publishings')}  
        >
          PUBLICATIONS
        </MenuItem>
        <MenuItem 
          style={{ backgroundColor: 'white', width: '100%' }}
          onClick={() => handleNavigation('/photos')}  
        >
          PHOTOS
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

export default MobileNavigationSidebar;
