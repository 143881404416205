import './Buttons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

function DiscardButton({onClick}) {
    return (
        <button className='edit-discard-button' onClick={onClick}>
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} onClick={onClick}/>
                Discard
        </button>
    )
}

export default DiscardButton