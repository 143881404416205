import React, { useRef, useEffect, useState } from 'react';
import './OpinionsPage.css';
import turkeyImage from '../../../assets/opinions-country-images/turkey.jpg'
import middleEastImage from '../../../assets/opinions-country-images/middle-east.jpg'
import unitedStatesImage from '../../../assets/opinions-country-images/united-states.jpeg'
import europeImage from '../../../assets/opinions-country-images/europe-union.jpeg'
import { API_BASE_URL, API_ENDPOINTS } from '../../../config';

export default function OpinionsPage(){
    return (
        <div className='page-main-div'>
            <div className='page-content-div'>
                <h2>OPINION</h2>
                <OpinionsSection/>
            </div>
        </div>
    )
}



function OpinionsSection(props){

    const [opinionsData,setOpinionsData] = useState({});
    const [loading,setLoading] = useState(true);
    const [latestOpinion, setLatestOpinion] = useState({});

    const foreignPolicyRef = useRef(null);
    const currentAffairsRef = useRef(null);
    const mediaRef = useRef(null);
    const regionRef = useRef(null);

    const latestOpinions = [
        {title:"Foreign Policy", image: turkeyImage, ref:foreignPolicyRef},
        {title:"Current Affairs", image: middleEastImage, ref:currentAffairsRef},
        {title:"Media", image: unitedStatesImage, ref:mediaRef},
        {title:"Region", image: europeImage, ref:regionRef}
    ]


    function getLatestOpinion(jsonData) {
        let latestOpinion = null;
        let latestDate = 0;
      
        // Iterate over each category in the JSON
        for (const category in jsonData) {
          if (jsonData.hasOwnProperty(category)) {
            // Iterate over each opinion in the category
            jsonData[category].forEach(opinion => {
              if (opinion.date > latestDate) {
                latestDate = opinion.date;
                latestOpinion = opinion;
              }
            });
          }
        }
      
        return latestOpinion;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.getOpinions;
                const response = await fetch(url);
                const responseData = await response.json();
                setOpinionsData(responseData);     
                const latest = getLatestOpinion(responseData);
                setLatestOpinion(latest);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);;
                setLoading(false);
            }
        };
        fetchData();
    },[]);

    if (loading) {
        return <p>Loading...</p>;
    } else {
        return (
            <div>
                <div className='card-div'>
                    <HeaderOpinion title={latestOpinion['title']} text={latestOpinion['description']} image={latestOpinion['imagePath']} id={latestOpinion['id']} subject={latestOpinion['subject']}/>
                    <OpinionSections latestOpinions={latestOpinions}/>
                </div>
                <div className='card-div' ref={foreignPolicyRef}>
                    <div className='card-content'>
                        <h2>Foreign Policy</h2>
                        {
                            opinionsData['foreign policy'].map((opinion => <OpinionListElement title={opinion['title']} text={opinion['description']} image={opinion['imagePath']}id={opinion['id']} subject={'foreign policy'}/>))
                        }
                    </div>
                </div>
                <div className='card-div' ref={currentAffairsRef}>
                    <div className='card-content'>
                        <h2>Current Affairs</h2>
                        {
                            opinionsData['current affairs'].map((opinion => <OpinionListElement title={opinion['title']} text={opinion['description']} image={opinion['imagePath']} id={opinion['id']} subject={'current affairs'}/>))
                        }
                    </div>
                </div>
                <div className='card-div' ref={mediaRef}>
                    <div className='card-content'>
                        <h2>Media</h2>
                        {
                            opinionsData['media'].map((opinion => <OpinionListElement title={opinion['title']} text={opinion['description']} image={opinion['imagePath']} id={opinion['id']} subject={'media'}/>))
                        }
                    </div>
                </div>
                <div className='card-div' ref={regionRef}>
                    <div className='card-content'>
                        <h2>Region</h2>
                        {
                            opinionsData['region'].map((opinion => <OpinionListElement title={opinion['title']} text={opinion['description']} image={opinion['imagePath']} id={opinion['id']} subject={'region'}/>))
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function OpinionSections(props) {
    return (
        <div className='opinion-sections-div' style={{justifyContent:'space-around',display: 'flex'}}>
            {
                props.latestOpinions.map((opinion, index) => (
                    <SmallBoxOpinion key={index} title={opinion.title} image={opinion.image} scrollRef={opinion.ref}/>
                ))
            }
        </div>
    );
}

function HeaderOpinion(props){
    const handleClick = () => {
        window.location.pathname = "/opinionEntry/" + props.subject + '/' + props.id;
    }
    return(
        <div className='header-opinion-div' onClick={handleClick}>
            <img src={process.env.PUBLIC_URL + props.image}/>
            <div>
                <h2 style={{fontSize:'2.5rem'}}>{props.title}</h2>
                <p style={{marginTop:'5%', fontSize:'1.5rem', fontWeight:'200'}}>
                    {props.text}
                </p>
            </div>
        </div>
    )
}

function OpinionListElement(props) {
    const handleClick = () => {
        window.location.pathname = "/opinionEntry/" + props.subject + '/' + props.id;
    }

    return (
        <div className='news-block' onClick={handleClick}>
            <div className='news-component'>
                <div className='opinion-element-content'>
                    <img src={process.env.PUBLIC_URL + props.image}/>
                </div>
                <div>
                    <h2>{props.title}</h2>
                    <h3>{props.text}</h3>
                </div>
            </div>
            <div className='divider'></div>
        </div>
    );
}


function SmallBoxOpinion(props){
    const handleClick = () => {
        console.log('click')
        if (props.scrollRef.current) {
          props.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='opinion-section-content-div' onClick={handleClick}>  
            <div>
                <h2 style={{paddingTop:'3%',paddingBottom:'3%', textAlign:'center'}}>{props.title}</h2>
            </div>
        </div>
    )
}

