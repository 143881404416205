import React, {useState, useEffect, useRef} from 'react';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Divider } from "@mui/joy";
import AddButton from '../../../Buttons/AddButton';
import './EditEntriesPage.css';
import { API_BASE_URL, API_ENDPOINTS } from '../../../../config';


const getTypeParamFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('type');
  };

export default function EditEntriesPage() {
    const [loading, setLoading] = useState(true);
    const [isAdmin, setAdmin] = useState();
    const [newsData,setNewsData] = useState([]);
    const [opinionsData, setOpinionsData] = useState([]);
    const [type,setType] = useState();


    const navigateToAddPage = () => {
        const typeParam = getTypeParamFromURL();
        if (typeParam === 'news') {
            window.location.href = '/addNewsPage';
        } else if (typeParam === 'opinions') {
            window.location.href = '/addOpinionPage';
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchType();
            await fetchAuth();
            if (isAdmin) {
                if (type === 'NEWS') {
                    await fetchNews();
                } else if (type === 'OPINIONS') {
                    await fetchOpinions();
                }
            }
        }

        const fetchType = () => {
            const typeParam = getTypeParamFromURL();
                if (typeParam === 'news') {
                    setType('NEWS');
                } else if (typeParam === 'opinions') {
                    setType('OPINIONS');
                }
        }


        const fetchNews = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.getNews;
                const response = await fetch(url);
                const responseData = await response.json();
                if (Array.isArray(responseData.news)) {
                  setNewsData(responseData.news);
                  setLoading(false);
                } else {
                  console.error('Invalid data received from the API:', responseData);
                }                
            } catch (error) {
                console.error('Error fetching data:', error);;
            }
        }

        const fetchOpinions = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.getOpinions;
                const response = await fetch(url);
                const responseData = await response.json();
                setOpinionsData(Object.values(responseData).flat());
                setLoading(false);     
            } catch (error) {
                console.error('Error fetching data:', error);;
            }
        }

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
            } catch (error) {
                console.error('Error fetching auth:', error);
            }
        }

        fetchData();
    }, [isAdmin])
    
    if (loading) {
        return <p>Loading...</p>
    }
    else if (isAdmin) {
        return (
            <div className='page-main-div'>
                <div className='page-content-div'>
                    <h2>
                        EDIT {type}
                    </h2>
                    <div className='card-div'>
                        <div className='card-content'>
                        {/* Conditional rendering based on the type */}
                        <AddButton onClick={navigateToAddPage}/>
                        {type === 'NEWS'
                            ? newsData.map((entry) => (
                                <EntryComponent entry={entry}/>
                            ))
                            : type === 'OPINIONS'
                            ? 
                            opinionsData.map((entry) => (
                                <EntryComponent entry={entry}/>
                            ))
                            : null // Handle other cases if needed
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return NotAllowedPage();
    }


    function EntryComponent(props) {
        const handleNavigate = () => {
            const id = props.id;
            if (type === 'NEWS') {
                window.location.href = "/editNews/" + props.entry.id;
            } else if (type === 'OPINIONS') {
                window.location.href = "/editOpinion/" + props.entry.subject + "/" + props.entry.id;
            }  
        } 

        const handleDelete = async () => {
            const shouldDelete = window.confirm('Are you sure you want to delete this entry?');
            if (shouldDelete) {
                if (type === 'NEWS') {
                    try {
                        const url = API_BASE_URL + API_ENDPOINTS.deleteNews + '/' + props.entry.id;
                        const response = await fetch(url, {
                            method: 'DELETE',
                            credentials: 'include',
                        });
        
                        if (response.status === 200) {
                            setAdmin(true);
                        } else {
                            setAdmin(false);
                        }
                    } catch (error) {
                        console.error('Error fetching auth:', error);
                    }
                } else if (type === 'OPINIONS') {
                    try {
                        const url = API_BASE_URL + API_ENDPOINTS.deleteOpinion + '/' + props.entry.id;
                        const response = await fetch(url, {
                            method: 'DELETE',
                            credentials: 'include',
                        });
        
                        if (response.status === 200) {
                            setAdmin(true);
                        } else {
                            setAdmin(false);
                        }
                    } catch (error) {
                        console.error('Error fetching auth:', error);
                    }
                }
            }
        };
        

        return (
            <div>
                <div className="admin-section-box">
                    <h2>{props.entry.title}</h2>
                    <div className="action-buttons">
                    <button className="edit-button" onClick={() => handleNavigate()} style={{ background: 'none', border: 'none', padding:'1rem', fontSize:'1.5rem'}}>
                            <FaEdit />
                    </button>
                    <button className="delete-button" onClick={() => handleDelete()} style={{ background: 'none', border: 'none',  fontSize:'1.5rem', color:'red'}}>
                        <FaTrash />
                    </button>
                    </div>
                </div>
                <Divider/>
            </div>
        )


    }
}