import React, { useState, useEffect } from 'react';
import { API_BASE_URL, API_ENDPOINTS } from '../../../config';


function NewsEntryPage(){
    const [jsonData, setJsonData] = useState({});
    const [loading, setLoading] = useState(true);
    const [relatedOpinions, setRelatedOpinions] = useState([]);


    useEffect(() => {
          const fetchData = async () => {
          const url = window.location.href;
          const segments = url.split('/').filter(segment => segment.trim() !== '');
        
        
          const idData = {'id':segments[segments.length - 1]};
        
          try {
            const newsByIdUrl = API_BASE_URL + API_ENDPOINTS.getNewsById;
            const response = await fetch(newsByIdUrl,{
                method: 'POST',
                headers: {
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(idData)
            });
            const data = await response.json();
            setJsonData(data);

            const urlSideOnes = API_BASE_URL + API_ENDPOINTS.getLatestNewsSideOnes;
            const relatedOpinionsResponse = await fetch(urlSideOnes,{
                method: 'POST',
                credentials: 'include',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(idData)
            });
            const dataRelatedOpinions = await relatedOpinionsResponse.json();
            setRelatedOpinions(dataRelatedOpinions);
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []); 

    const renderedRelatedOpinions = relatedOpinions.map((opinion,index) => (
        <div>
            <LatestNews key={index} id={opinion.id} title={opinion.title} subtitle={opinion.description} onclick={opinion.id}/>
            <div className='divider' style={{width:'22vw'}}/>
        </div>
    ));

    const date = new Date(jsonData.date);

    // Get individual components of the date
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date as MM/DD/YYYY
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

    if (loading) {
        return <p>Loading...</p>
    } else {
        return ( 
            <div className='page-main-div'>
                <div className='page-content-div'>
                    <div className='card-div'>
                        <div className='card-content'>
                            <h4 style={{marginBottom:'1rem',color:'grey', fontWeight:'bold'}}>{formattedDate}</h4>
                            <h2>{jsonData['title']}</h2>
                            <h3 className='opinion-subtitle'>{jsonData['description']}</h3>
                            <div style={{display:'flex',marginTop:'2.5rem'}}>
                                <div className='opinion-image-and-title'>
                                    <img src={process.env.PUBLIC_URL + jsonData['imagePath']}/>
                                    <h3>{jsonData.imageSource}</h3>
                                    <div className='opinion-text'>
                                        <p>{jsonData['text']}</p>
                                    </div>
                                </div>
                                <div className='div-related-opinions'>
                                    <h3>LATEST NEWS</h3>
                                    <div className='divider' style={{width:'22vw'}} />
                                    {renderedRelatedOpinions}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function LatestNews(props){
        const onClickAction = () => {
            window.location.href = "/newsEntry/" + props.id;
        }
    
        return (
            <div className= 'related-opinion' onClick={onClickAction}>
                <h2 style={{fontSize:'1.5rem',}}>
                    {props.title}
                </h2>
                <h3 style={{fontSize:'1rem',fontWeight:'250',paddingTop:'2%'}}>
                    {props.subtitle}
                </h3>
            </div>
        )
    }
}

export default NewsEntryPage