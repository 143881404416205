import React, { useRef, useEffect, useState } from 'react';
import './PhotosPage.css'
import { API_BASE_URL, API_ENDPOINTS } from '../../../config';

function PhotosPage() {

  const [loading,setLoading] = useState(true);
  const [photos, setPhotos] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const openModal = (index, section) => {
    setSelectedImageIndex(index);
    setSelectedSection(section);
    setModalOpen(true);
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setSelectedImageIndex(null);
    setSelectedSection(null);
    setModalOpen(false);
  };
  
  useEffect(() => {
      const fetchData = async () => {
          try {
              const url = API_BASE_URL + API_ENDPOINTS.getPhotos;
              const response = await fetch(url);
              const responseData = await response.json();
              console.log(responseData)
              setPhotos(responseData);
              setLoading(false);
          } catch (error) {
              console.error('Error fetching data:', error);;
              setLoading(false);
          }
      };
      fetchData();
  },[]);


    if (loading) {
      return <p>Loading...</p>
    }
    else {
      return (
        <div className="page-main-div">
          <div className="page-content-div">
            <h2>PHOTOS</h2>
            <div className="card-div">
              <div className="card-content">
                <h2>With Leaders</h2>
                <div className="photos-container">
                  {photos['photos']
                    .filter((photo) => photo['section'] === 'with leader')
                    .map((photo, index) => (
                      <div key={index} onClick={() => openModal(index,'with leader')}>
                        <PhotoBox photo={photo} />
                      </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="card-div">
              <div className="card-content">
              <h2>On field</h2>
                <div className="photos-container">
                  {photos['photos']
                    .filter((photo) => photo['section'] === 'on field')
                    .map((photo, index) => (
                      <div key={index} onClick={() => openModal(index, 'on field')}>
                        <PhotoBox photo={photo} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="card-div">
              <div className="card-content">
                <h2>Interviews</h2>
                <div className="photos-container">
                  {photos['photos']
                    .filter((photo) => photo['section'] === 'interviews')
                    .map((photo, index) => (
                      <div key={index} onClick={() => openModal(index, 'interviews')}>
                        <PhotoBox photo={photo} />
                      </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="card-div">
              <div className="card-content">
              <h2>Memorables</h2>
                <div className="photos-container">
                  {photos['photos']
                    .filter((photo) => photo['section'] === 'memorables')
                    .map((photo, index) => (
                      <div key={index} onClick={() => openModal(index, 'memorables')}>
                        <PhotoBox photo={photo} />
                      </div>
                  ))}
                </div>
              </div>
              </div>
            </div>
            {

            isModalOpen && selectedImageIndex !== null && (
              <div className="modal">
                <span className="close" onClick={(e) => closeModal(e)}>
                  &times;
                </span>
                <img
                  className="modal-content"
                  src={process.env.PUBLIC_URL + photos['photos'].filter((photo) => photo['section'] === selectedSection)[selectedImageIndex]['photoPath']}
                  alt={`Cinematic View ${selectedImageIndex + 1}`}
                  onClick={(e) => e.stopPropagation()}
                />
                <div className="description">
                  {
                    photos['photos'].filter((photo) => photo['section'] === selectedSection)[selectedImageIndex]['title']
                  }
                </div>
              </div>
            )}
        </div>
      );
    }
  }
  
  export default PhotosPage;

  function PhotoBox(props) {
    const imageSource = process.env.PUBLIC_URL + props.photo['photoPath'];
  
    // Create a new Date object with milliseconds since epoch
    const date = new Date(props.photo['date']);

    // Get individual components of the date
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const day = date.getDate();
    const year = date.getFullYear();

    // Format the date as MM/DD/YYYY
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

    return (
      <div className="photo-box-container">
        <img
          src={imageSource}
          alt={`Image ${imageSource}`}
        />
        <h4 style={{color:'grey', marginBottom:'0.5rem'}}>{formattedDate}</h4>
        <h3>{props.photo['title']}</h3>
      </div>
    );
  }
  