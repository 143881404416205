import React, { useEffect, useState } from "react";
import {FaTrash } from 'react-icons/fa';
import NotAllowedPage from "../../../NotAllowedPage/NotAllowedPage";
import AddButton from '../../../Buttons/AddButton';
import { API_BASE_URL, API_ENDPOINTS } from "../../../../config";

export default function EditPublishingsPage() {

    const [publishingsData, setPublishingsData] = useState({});
    const [loading,setLoading] = useState(true);
    const [isAdmin, setAdmin] = useState();

    const handleAddButtonAction = () => {
        window.location.href = '/addPublishing';
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchAuth();
                const url = API_BASE_URL + API_ENDPOINTS.getPDFs;
                const response = await fetch(url);
                const responseData = await response.json();
                if (Array.isArray(responseData.publishings)) {
                    setPublishingsData(responseData.publishings);
                } else {
                    console.error('Invalid data received from the API: ', responseData);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);;
                setLoading(false);
            }
        };

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
            } catch (error) {
                console.error('Error fetching auth:', error);
            }
        }


        fetchData();
     },[isAdmin]);

    if (loading) {
        return <p>Loading...</p>;
    } else if (isAdmin) {
            return (
                <div className="page-main-div">
                    <div className="page-content-div">
                        <h2>EDIT PUBLISHINGS</h2>
                        <AddButton onClick={handleAddButtonAction}/>
                        <div style={{margin:'2rem'}}>                
                        </div>
                        <div className="card-div">
                            <div className="card-content">
                                {
                                    publishingsData.map((pub) => <PublishingComponent id={pub.id} thumbnailPath={pub.thumbnailPath} title={pub.title} description={pub.description} date={pub.date}/>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
    } else {
        return NotAllowedPage();
    }



    function PublishingComponent(props) {
        // Create a new Date object with milliseconds since epoch
        const date = new Date(props.date);

        // Get individual components of the date
        const month = date.getMonth() + 1; // Months are zero-based, so add 1
        const day = date.getDate();
        const year = date.getFullYear();

        // Format the date as MM/DD/YYYY
        const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

        const handleDelete = async () => {
            const shouldDelete = window.confirm('Are you sure you want to delete this entry?');
        
            if (shouldDelete) {
                try {
                    const url = API_BASE_URL + API_ENDPOINTS.deletePDF + '/' + props.id;
                    const response = await fetch(url, {
                        method: 'DELETE',
                        credentials: 'include',
                    });
    
                    console.log(response);
                } catch (error) {
                    console.error('Error fetching auth:', error);
                }
            }
        };

        
        return (
            <div className='publishing-block'>
                <div className='publishing-component'>
                    <img src={process.env.PUBLIC_URL + props.thumbnailPath}/>
                    <div>
                        <div style={{display:'flex'}}>
                            <h2>{props.title}</h2>
                            <button className="delete-button" onClick={() => handleDelete()} style={{ background: 'none', border: 'none',  fontSize:'1.5rem', color:'red', marginLeft:'5rem'}}>
                                <FaTrash />
                            </button>
                        </div>
                        <h4>{formattedDate}</h4>
                        <h3>{props.description}</h3>
                    </div>
                </div>

                <div className='divider'/>
            </div>
        )
    }
}