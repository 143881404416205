import React, { useState, useEffect, useRef } from 'react';
import SaveButton from '../../../Buttons/SaveButton';
import DiscardButton from '../../../Buttons/DiscardButton';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import {toast } from 'react-toastify';
import { API_BASE_URL, API_ENDPOINTS } from '../../../../config';

const getSuccessParamFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('success');
  };

function EditNewsPage(){
    const [jsonData, setJsonData] = useState({});
    const [titleText, setTitleText] = useState('');
    const [descriptionText, setDescriptionText] = useState('');
    const [newsText, setNewsText] = useState('');
    const [image, setImage] = useState();
    const [imageSourceText, setImageSourceText] = useState('');
    const [imageFile, setImageFile] = useState();
    const [imagePath, setImagePath] = useState();
    const [isAdmin, setAdmin] = useState();
    const [loading, setLoading] = useState(true);
    const [isChanged, setChanged] = useState(false);
    const showToastRef = useRef(false);

    useEffect(() => {
        const fetchNews = async () => {
            const url = window.location.href;
            const segments = url.split('/').filter(segment => segment.trim() !== '');
          
          
            const idData = {'id':segments[segments.length - 1]};
          
            try {
              const url = API_BASE_URL + API_ENDPOINTS.getNewsById;
              const response = await fetch(url,{
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                      'Content-Type':'application/json'
                  },
                  body: JSON.stringify(idData)
              });
              const data = await response.json();
              setJsonData(data);
              setLoading(false);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        }

        const fetchData = async () => {
            await fetchAuth();
            if (isAdmin) {
                await fetchNews();
                setTitleText(jsonData['title']);
                setDescriptionText(jsonData['description']);
                setNewsText(jsonData['text']);
                setImageSourceText(jsonData['imageSource']);
                setImagePath(process.env.PUBLIC_URL + jsonData['imagePath']);
                const successParam = getSuccessParamFromURL();
                if (successParam === '1' && !showToastRef.current) {
                    toast.success('News is updated', { position: 'top-center' });
                    showToastRef.current = true;
                } else if (successParam === '0' && !showToastRef.current) {
                    toast.error('Error updating news', { position: 'top-center' });
                    showToastRef.current = true;
                }
            }
        }

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
            } catch (error) {
                console.error('Error fetching auth:', error);
            }
        }

        fetchData();


        const handleBeforeUnload = (event) => {
            if (isChanged) {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message;
            return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

      
    },[isChanged,isAdmin,loading])

    const handleTitleTextChange = (event) => {
        setChanged(true);
        setTitleText(event.target.value);
    }

    const handleDescriptionTextChange = (event) => {
        setChanged(true);
        setDescriptionText(event.target.value);
    }

    const handleNewsTextChange = (event) => {
        setChanged(true);
        setNewsText(event.target.value);
    }

    const handleImageClick = () => {
        // Trigger the hidden file input when the image is clicked
        inputImageRef.current.click();
    };
    
    const handleImageChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
        setChanged(true);
          setImage(URL.createObjectURL(file));
          setImageFile(file);
        }
      };
      
  
    const handleImageSourceTextChange = (event) => {
        setChanged(true);
        setImageSourceText(event.target.value);
    }

    const   handleReload = () => {
        window.location.reload();
      };

    const inputImageRef = React.useRef(null);


    const uploadData = async (base64Data) => {
        const opinionData = {
            "id":jsonData['id'],
            "title": titleText,
            "date":jsonData['date'],
            "description": descriptionText,
            "text": newsText,
            "image": base64Data !== null ? base64Data : "no image",
            "imageSource": imageSourceText
        };
        try {
            const url = API_BASE_URL + API_ENDPOINTS.updateNews;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(opinionData)
            });

            const result = await response.json();

            if (result.success) {
                console.log('Data saved successfully!');
                window.location.href = '/addNewsPage?success=1';
            } else {
                console.error('Failed to save data:', result.error);
                window.alert('Data is not saved');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    }

    const handleSave = async () => {
        setChanged(false);
        try {
            const reader = new FileReader();
            if (imageFile) {
                reader.onloadend = async () => {
                    const base64Data = reader.result.split(',')[1];
                    uploadData(base64Data);
                };
            } else {
              uploadData();
            }

            reader.readAsDataURL(imageFile);
        } catch (error) {
            console.error('Error reading image file:', error);
        }

    };

    if (loading) {
        return <p>Loading...</p>
    }
    else if (isAdmin) {

                return ( 
                    <div className='page-main-div'>
                        <div className='page-content-div'>
                            <h2>
                                ADD NEWS
                            </h2>
                            <div className='card-div'>
                                <div className='card-content'>
                                    <h3 style={{fontSize:'2rem'}}>Title</h3>
                                    <textarea
                                        type='text'
                                        className='news-edit-title-input'
                                        value={titleText}
                                        onChange={handleTitleTextChange}
                                        placeholder='Add title...'
                                    />
                                    <h3 style={{fontSize:'2rem',paddingTop:'1rem'}}>Description</h3>
                                    <textarea
                                        type='text'
                                        className='news-edit-description-input'
                                        value={descriptionText}
                                        onChange={handleDescriptionTextChange}
                                        placeholder='Add description...'
                                    />
                                    <div style={{display:'flex',marginTop:'2.5rem'}}>
                                        <div className='opinion-image-and-title'>
                                            <input
                                                type='file'
                                                accept='image/*'
                                                ref={inputImageRef}
                                                style={{ display: 'none' }}
                                                onChange={handleImageChange}
                                            />
                                            {image ? (
                                                <img style={{height:'25rem', width:'auto', maxWidth:'100%'}} src={image} onClick={handleImageClick} alt="Selected Image" />
                                            ) : (
                                                <img
                                                    src={imagePath}
                                                    style={{height:'25rem', width:'auto', maxWidth:'100%'}}
                                                    onClick={handleImageClick}
                                                />
                                            )}
                                            <textarea
                                                type='text'
                                                className='news-edit-image-source-input'
                                                value={imageSourceText}
                                                onChange={handleImageSourceTextChange}
                                                placeholder='Add image source...'
                                            />
                                        </div>
                                    </div>
                                    <div className='opinion-text'>
                                        <textarea
                                            type='text'
                                            className='news-edit-news-text-input'
                                            value={newsText}
                                            onChange={handleNewsTextChange}
                                            placeholder='Add news text...'
                                        />
                                    </div>
                                    <div style={{display:'flex'}}>
                                        <SaveButton onClick={handleSave}/>
                                        <DiscardButton onClick={handleReload}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return NotAllowedPage();
            }
        }

export default EditNewsPage