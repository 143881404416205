import {React,useState, useEffect} from "react";
import SectionBox from "./SectionBox";
import { Divider } from "@mui/joy";
import NotAllowedPage from "../../NotAllowedPage/NotAllowedPage";
import { API_BASE_URL, API_ENDPOINTS } from "../../../config";


export default function AdminInterfacePage() {
    const [isAdmin, setAdmin] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await fetchAuth();
            setLoading(false);
        }

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching auth:', error);
                setLoading(false);
            }
        }
        fetchData();
    },[])

    if (loading) {
        return <p>Loading...</p>

    } else if (!isAdmin) {
        return NotAllowedPage();

    } else {
        return (
            <div className="page-main-div">
                <div className="page-content-div">
                    <h2>
                        ADMIN
                    </h2>
                    <div className="card-div">
                        <div className="card-content">
                            <div>
                                
                                <SectionBox title="Edit News" page="/editEntries?type=news"/>
                                <Divider/>
                                <SectionBox title="Edit Opinions" page="/editEntries?type=opinions"/>
                                <Divider/>
                                <SectionBox title="Edit Publishing" page="/editPublishings"/>
                                <Divider/>
                                <SectionBox title="Edit Video" page="/editVideos"/>
                                <Divider/>
                                <SectionBox title="Edit Photos" page="/editPhotos"/>
                                <Divider/>
                                <SectionBox title="Edit Bio" page="/bioEditPage"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}