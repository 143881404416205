import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSave} from '@fortawesome/free-solid-svg-icons';

function SaveButton({onClick}) {

    return (
        <button className='edit-save-button' onClick={onClick} >
        <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
            Save
        </button> )
}

export default SaveButton