import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import {FaTrash } from 'react-icons/fa';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import AddButton from '../../../Buttons/AddButton';
import { API_BASE_URL, API_ENDPOINTS } from '../../../../config';


export default function EditTVPage(){
    const [videosData,setVideosData] = useState([]);
    const [loading,setLoading] = useState(true);
    const [isAdmin, setAdmin] = useState();

    const navigateToAddVideo = () => {
        window.location.href = '/addVideo';
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchAuth();
                const url = API_BASE_URL + API_ENDPOINTS.getVideos;
                const response = await fetch(url)
                const responseData = await response.json();
                if (Array.isArray(responseData.videos)) {
                    setVideosData(responseData.videos);
                } else {
                    console.error('Invalid data received from the API:', responseData);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);;
                setLoading(false);
            }
        };

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
            } catch (error) {
                console.error('Error fetching auth:', error);
            }
        }

        fetchData();
    },[isAdmin]);

    if (loading) {
        return "Loading"
    } else if (isAdmin) {
        return (
            <div className='tv-page-main-div'>
                <div className='tv-page-content-div'>
                    <h2>TV</h2>
                    <AddButton onClick={navigateToAddVideo}/>
                    <TVSection title='Interviews'/>
                    <TVSection title='Programs'/>
                    <TVSection title='Documentaries'/>
                </div>
            </div>
        )
    } else {
        return NotAllowedPage();
    }


function TVSection(props){
    let videos = videosData.filter(video =>  video.section === props.title.toLowerCase());
    console.log(videos);
    return (    
        <div className='card-div'>
            <div className='card-content'>
                <h2>
                    {props.title}
                </h2>
                
                {
                     videos.map((video, index) => (
                        <VideoSection key={index} videoId={video.videoId} description={video.description} title={video.title}  />
                    ))
                }
            </div>
        </div>
    )
}


function VideoSection(props){

    const opts = {
        playerVars: {
            autoplay: 0,
        },
    };

    const handleDelete = async () => {
        const shouldDelete = window.confirm('Are you sure you want to delete this entry?');
    
        if (shouldDelete) {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.deleteVideo + '/' + props.videoId;
                const response = await fetch(url, {
                    method: 'DELETE',
                    credentials: 'include',
                });

                console.log(response);
            } catch (error) {
                console.error('Error fetching auth:', error);
            }
        }
    };

    
    return (
        <div className='video-section'>
                <div>
                    <p>{props.description}</p>
                    <div className='video-container'>
                        <YouTube style={{marginBottom:'2%'}} videoId={props.videoId} opts={opts}/>
                    </div>
                </div>
                <button className="delete-button" onClick={() => handleDelete()} style={{ background: 'none', border: 'none',  fontSize:'2.5rem', color:'red'}}>
                        <FaTrash />
                    </button>
                <div className='divider'></div>
        </div>
        
    )
}
}