import {React, useState, useEffect} from 'react';
import './MainPage.css';
import SlideShowComponent from './SlideshowComponent';
import boraEmmyImage from '../../../bora-bayraktar-images/bora-and-emmy.jpeg';
import boraUniversityImage from '../../../bora-bayraktar-images/bora-university.jpeg';
import boraSpeechImage from '../../../bora-bayraktar-images/bora-speech.jpeg';
import MainPageBioContent from './MainPageBioContent';
import MainPageTvContent from './MainPageTvContent';
import LatestOpinionsContent from './LatestOpinions';
import { API_BASE_URL, API_ENDPOINTS } from '../../../config';


const slides = [
    {
      image: boraEmmyImage,
      title: 'Bora Bayraktar and his team won an Emmy Award',
      body: "TRT World's news-documentary, adjudged winner of 44th International Emmy Award in news category, examines transformation in the lives of Ukrainian civilians following withdrawal of Russian army from some Ukrainian areas.",
    },
    {
      image: boraUniversityImage,
      title: 'Bora Bayraktar visited Kültür University',
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum dictum diam, eget egestas libero. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur ullamcorper quam sed ante facilisis, at bibendum nulla  sodales. Donec et felis eget enim luctus auctor."
    },
    {
      image: boraSpeechImage,
      title: 'Türkiye-Azerbeijan Conference',
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent interdum dictum diam, eget egestas libero. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Curabitur ullamcorper quam sed ante facilisis, at bibendum nulla  sodales. Donec et felis eget enim luctus auctor.",
    },
  ];



export default function MainPage(){

  const [newsData,setNewsData] = useState([]);
  const [opinionsData,setOpinionsData] = useState([]);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await fetchNewsData();
      await fetchOpinionsData();
      setLoading(false);
    }

    const fetchNewsData = async () => {
        try {
            const url = API_BASE_URL + API_ENDPOINTS.getLatestNews;
            const response = await fetch(url);
            const responseData = await response.json();
            if (Array.isArray(responseData.news)) {
              setNewsData(responseData.news);
            } else {
              console.error('Invalid data received from the API:', responseData);
            }                
        } catch (error) {
            console.error('Error fetching data:', error);;
            setLoading(false);
        }
    };

    const fetchOpinionsData = async () => {
      try {
        const url = API_BASE_URL + API_ENDPOINTS.getLatestOpinions;
        const response = await fetch(url);
        const responseData = await response.json();
        console.log(opinionsData);
        setOpinionsData(responseData);     
      } catch (error) {
        console.error('Error fetching data:', error);;
        setLoading(false);
      }


    }

    fetchData();
    },[]);

    if (loading) {
      return "Loading"
    } else {
      return ( 
        <div className='home-page-div'>
          <div className='slideshow-div'>
              <SlideShowComponent slides ={newsData}/>
          </div>
          <LatestOpinionsContent latestOpinions= {opinionsData}/>
        </div>
      )
    }
}