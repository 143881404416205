import React, { useState, useEffect } from 'react';
import './TVPage.css'
import boraInterview from '../../../bora-bayraktar-images/bora-interview.jpeg'
import YouTube from 'react-youtube';
import { API_BASE_URL, API_ENDPOINTS } from '../../../config';


export default function TVPage(){
    let lorem_ipsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean congue ut lorem sed varius. Praesent vitae maximus mi, at euismod mi. Aenean eget ex nec lacus laoreet iaculis. Aliquam ornare, dui sit amet porttitor aliquam, nibh ligula dignissim elit, at vestibulum magna leo a purus. Praesent nunc nisi, maximus in urna in, euismod ullamcorper diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec euismod porttitor viverra. Nam at neque non dui lobortis mollis. Fusce venenatis libero nec quam rhoncus, sit amet euismod mi aliquet. Proin finibus scelerisque aliquet. Nullam laoreet rhoncus est quis aliquet. Nulla vitae urna diam. Aliquam eget neque pulvinar, cursus lorem ut, efficitur magna. Duis blandit placerat purus at congue."
    const [videosData,setVideosData] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.getVideos;
                const response = await fetch(url);
                console.log('Data is fetched');
                const responseData = await response.json();
                if (Array.isArray(responseData.videos)) {
                    setVideosData(responseData.videos);
                } else {
                    console.error('Invalid data received from the API:', responseData);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);;
                setLoading(false);
            }
        };
        fetchData();
    },[]);

    if (loading) {
        return "Loading"
    } else {
        return (
            <div className='tv-page-main-div'>
                <div className='tv-page-content-div'>
                    <h2>TV</h2>
                    <TVSection title='Interviews'/>
                    <TVSection title='Programs'/>
                    <TVSection title='Documentaries'/>
                </div>
            </div>
        )
    }


function TVSection(props){
    let videos = videosData.filter(video =>  video.section === props.title.toLowerCase());
    console.log(videos);
    return (    
        <div className='card-div'>
            <div className='card-content'>
                <h2>
                    {props.title}
                </h2>
                
                {
                     videos.map((video, index) => (
                        <VideoSection key={index} videoId={video.videoId} description={video.description} title={video.title}  />
                    ))
                }
            </div>
        </div>
    )
}


function VideoSection(props){

    const opts = {
        playerVars: {
            autoplay: 0,
        },
    };
    
    return (
        <div className='video-section'>
                <div>
                    <p>{props.description}</p>
                    <div className='video-container'>
                        <YouTube style={{marginBottom:'2%'}} videoId={props.videoId} opts={opts}/>
                    </div>
                </div>
                <div className='divider'></div>
        </div>
        
    )
}
}