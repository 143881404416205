import React, { useState, useEffect, useRef } from 'react';
import {toast } from 'react-toastify';
import PhotoSubjectComboBoxComponent from './PhotoSubjectComboBoxComponent';
import SaveButton from '../../../Buttons/SaveButton';
import DiscardButton from '../../../Buttons/DiscardButton';
import NotAllowedPage from '../../../NotAllowedPage/NotAllowedPage';
import { API_BASE_URL, API_ENDPOINTS } from '../../../../config';

const getSuccessParamFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('success');
  };


export default function AddPhotoPage(){
    const [titleText, setTitleText] = useState('');
    const [date, setDate] = useState(new Date());
    const [image, setImage] = useState();
    const [imageFile, setImageFile] = useState();
    const [section, setSection] = useState('');
    const [loading, setLoading] = useState(true);
    const [isAdmin, setAdmin] = useState();
    const [isChanged, setChanged] = useState(false);
    const showToastRef = useRef(false);
    const inputImageRef = React.useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            await fetchAuth();
            setLoading(false);
            const successParam = getSuccessParamFromURL();
            if (successParam === '1' && !showToastRef.current) {
                toast.success('Photo is added', { position: 'top-center' });
                showToastRef.current = true;
            } else if (successParam === '0' && !showToastRef.current) {
                toast.error('Error uploading photo', { position: 'top-center' });
                showToastRef.current = true;
            }
        }

        const fetchAuth = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.checkAuthorization;
                const response = await fetch(url,
                {
                    method: 'GET',
                    credentials: 'include',
                }
                );
                if (response.status == 200) {
                    setAdmin(true)
                } else {
                    
                    setAdmin(false);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching auth:', error);
                setLoading(false);
            }
        }
        fetchData();

        const handleBeforeUnload = (event) => {
            if (isChanged) {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message;
            return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };


    },[isChanged]);

    const handleTitleTextChange = (event) => {
        setChanged(true);
        setTitleText(event.target.value);
    }
    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        const parsedDate = new Date(selectedDate);
        
        // Check if the parsedDate is a valid date
        if (!isNaN(parsedDate.getTime())) {
            setDate(parsedDate);
        } else {
            console.error('Invalid date format:', selectedDate);
            // You might want to handle invalid dates here, such as showing an error message to the user.
        }
    };

    const handleSectionChange = (selectedValue) => {
        setChanged(true);
        setSection(selectedValue);
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setChanged(true);
            setImage(URL.createObjectURL(file));
            setImageFile(file);
        }
    };


    const handleImageClick = () => {
        inputImageRef.current.click();
    };

    const  handleReload = () => {
        window.location.reload();
    };


    const handleSave = async () => {
        setChanged(false);
        var millisecondsSinceEpoch = date.getTime();
        if (imageFile) {
            if (section == '') {
                window.alert('Please select a section');
            } else {
                try {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                        const base64Data = reader.result.split(',')[1];
                        const photoData = {
                            "title":titleText,
                            "image":base64Data,
                            "date": millisecondsSinceEpoch,
                            "section": section
                        };
                        try {
                            const url = API_BASE_URL + API_ENDPOINTS.addPhoto;
                            const response = await fetch(url, {
                                method: 'POST',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(photoData)
                            });

                            const result  = await response.json();

                            if (result.success) {
                                console.log('Data saved successfully!');
                                window.location.href = '/addPhoto?success=1';
                            } else {
                                console.error('Failed to save data:', result.error);
                                window.location.href = '/addPhoto?success=0';
                            }

                            
                        } catch (error) {
                            console.error('Error saving data:', error);
                        }
                    };
                    reader.readAsDataURL(imageFile);
                } catch (error) {
                    console.error('Error reading image file:', error);
                }
            }
        } else {
            window.alert('Please select an image');
            console.error('No image file selected.');
        }
    };

    if (loading) {
        return <p>Loading...</p>
    } else if (isAdmin) {
        return (
            <div className='page-main-div'>
                <div className='page-content-div'>
                    <h2>
                        ADD PHOTO
                    </h2>
                    <div className='card-div'>
                        <div className='card-content'>
                            <h3 style={{fontSize:'2rem',marginBottom:'1rem'}}>Subject</h3>
                            <PhotoSubjectComboBoxComponent onChange={handleSectionChange}/>
                            <h3 style={{fontSize:'2rem'}}>Title</h3>
                            <textarea
                                type='text'
                                className='news-edit-title-input'
                                value={titleText}
                                onChange={handleTitleTextChange}
                                placeholder='Add title...'
                            />
                            <h3 style={{fontSize:'2rem',paddingTop:'1rem'}}>Date</h3>
                            <input style={{marginTop:'2rem', fontSize:'1rem'}} type="date" id="dateInput" name="dateInput" onChange={handleDateChange}></input>
                            <h3 style={{ fontSize: '2rem', paddingTop: '1rem' }}>Photo</h3>
                            <input
                                type='file'
                                accept='image/*'
                                ref={inputImageRef}
                                style={{ display: 'none' }}
                                onChange={handleImageChange}
                            />
                            {image ? (
                                <img style={{height:'25rem', width:'auto', maxWidth:'100%'}} src={image} onClick={handleImageClick} alt="Selected Image" />
                            ) : (
                                <div
                                    style={{
                                        width: 'auto',
                                        maxWidth: '100%',
                                        height: '20rem',
                                        border: '1px solid #ddd',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleImageClick}
                                >
                                    <span style={{ color: '#777' }}>Select Image</span>
                                </div>
                            )}
                            <div style={{display:'flex', marginTop:'1rem'}}>
                                <SaveButton onClick={handleSave}/>
                                <DiscardButton onClick={handleReload}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        const page = NotAllowedPage();
        return page;
    }

    
    
}