import './App.css';
import NavigationBarComponent from './components/NavigationBar/NavigationBarComponent';
import BioPage from './components/pages/BioPage/BioPage';
import ContactBannerComponent from './components/ContactBanner/ContactBannerComponent';
import TVPage from './components/pages/TVPage/TVPage';
import OpinionsPage from './components/pages/OpinionsPage/OpinionsPage';
import OpinionEntryPage from './components/pages/OpinionEntryPage/OpinionEntryPage';
import MainPage from './components/pages/MainPage/MainPage';
import NewsPage from './components/pages/NewsPage/NewsPage';
import NewsEntryPage from './components/pages/NewsEntryPage/NewsEntryPage';
import PhotosPage from './components/pages/PhotosPage/PhotosPage';
import BioEditPage from './components/pages/EditorPages/BioEditPage/BioEditPage';
import AddNewsPage from './components/pages/EditorPages/AddNewsPage/AddNewsPage';
import AddOpinionPage from './components/pages/EditorPages/AddOpinionPage/AddOpinionPage';
import AddVideoPage from './components/pages/EditorPages/AddVideoPage/AddVideoPage';
import NotFoundPage from './components/pages/NotFoundPage/NotFoundPage';
import PublishingsPage from './components/pages/PublishingsPage/PublishingsPage';
import AddPublishingPage from './components/pages/EditorPages/AddPublishingPage/AddPublishingPage';
import AdminLogin from './components/pages/AdminLoginPage/AdminLoginPage';
import AdminInterfacePage from './components/pages/AdminInterface/AdminInterfacePage';
import AddPhotoPage from './components/pages/EditorPages/AddPhotoPage/AddPhotoPage';
import EditEntriesPage from './components/pages/EditorPages/EditEntriesPage/EditEntriesPage';
import EditNewsPage from './components/pages/EditorPages/EditNewsPage/EditNewsPage';
import EditOpinionPage from './components/pages/EditorPages/EditOpinionsPage/EditOpinionPage';
import EditPhotosPage from './components/pages/EditorPages/EditPhotosPage/EditPhotosPage';
import EditPublishingsPage from './components/pages/EditorPages/EditPublishingsPage/EditPublishingsPage';
import EditTVPage from './components/pages/EditorPages/EditVideoPage/EditVideoPage';

function App() {
  let Component
   // Extract the common prefix
   const commonPrefix = window.location.pathname.split('/')[1];

   // Switch based on the common prefix
   switch (commonPrefix) {
     case "":
       Component = MainPage;
       break;
     case "tv":
       Component = TVPage;
       break;
     case "bio":
       Component = BioPage;
       break;
     case "opinions":
       Component = OpinionsPage;
       break;
     case "opinionEntry":
       Component = OpinionEntryPage;
       break;
     case "news":
       Component = NewsPage;
       break;
     case "newsEntry":
       Component = NewsEntryPage;
       break;
     case "photos":
       Component = PhotosPage;
       break;
     case "bioEditPage":
       Component = BioEditPage;
       break;
     case "addNewsPage":
       Component = AddNewsPage;
       break;
     case "addOpinionPage":
       Component = AddOpinionPage;
       break;
     case "addVideo":
       Component = AddVideoPage;
       break;
     case "publishings":
       Component = PublishingsPage;
       break;
     case "addPublishing":
       Component = AddPublishingPage;
       break;
      case  "addPhoto":
        Component = AddPhotoPage;
        break;
     case "adminLogin":
       Component = AdminLogin;
       break;
     case "admin":
       Component = AdminInterfacePage;
       break;
      case "editEntries":
        Component = EditEntriesPage;
        break;
      case "editNews":
        Component = EditNewsPage;
        break;
      case "editOpinion":
        Component = EditOpinionPage;
        break;
      case "editPhotos":
        Component = EditPhotosPage;
        break;
      case "editPublishings":
        Component = EditPublishingsPage;
        break;
      case "editVideos":
        Component = EditTVPage;
        break;
     default:
       Component = NotFoundPage;
   }
  

  return (
    <div className="App">
      <header className="App-header">
        <NavigationBarComponent/>
      </header>
      <Component/>
      <ContactBannerComponent/>
    </div>
  );
}

export default App;
