import React, { useState } from 'react';

const ComboBoxComponent = ({ onChange }) => {
  // State to track the selected value
  const [selectedValue, setSelectedValue] = useState('');

  // Handler function to update the selected value and call the parent callback
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

    // Call the parent callback with the selected value
    onChange(value);
  };

  const style = {
    height: '3rem',
    width: '10rem',
    fontSize: '1rem',
  };

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <select id="comboBox" value={selectedValue} onChange={handleSelectChange} style={style}>
        <option value="">Select...</option>
        <option value="foreign policy">Foreign Policy</option>
        <option value="current affairs">Current Affairs</option>
        <option value="media">Media</option>
        <option value="region">Region</option>
      </select>
    </div>
  );
};

export default ComboBoxComponent;
