import React, {useState, useEffect} from 'react'
import './NewsPage.css'
import { API_BASE_URL, API_ENDPOINTS } from '../../../config';

function NewsPage() {

    const [newsData,setNewsData] = useState({});
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = API_BASE_URL + API_ENDPOINTS.getNews;
                const response = await fetch(url);
                const responseData = await response.json();
                if (Array.isArray(responseData.news)) {

                    const sortedNewsData = responseData.news.sort((a, b) => {
                        return b.date - a.date; // Compare newsDate numerically in descending order
                    });
                    setNewsData(sortedNewsData);

                } else {
                    console.error('Invalid data received from the API:', responseData);
                }                
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);;
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    
    
    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className='page-main-div'>
            <div className='page-content-div'>
                <h2>NEWS</h2>
                <div className='card-div'>
                    <div className='card-content'>
                        {
                            newsData.map((news) => <NewsComponent id={news.id} image={news.imagePath} title={news.title} description={news.description} date={news.date} />)
                        }
                    </div>
                </div>

            </div>
        </div>
    )


    function NewsComponent(props) {
        const handleClick = () => {
            const id = props.id;
            window.location.pathname = "/newsEntry/" + id;
        } 
        // Create a new Date object with milliseconds since epoch
        const date = new Date(props.date);

        // Get individual components of the date
        const month = date.getMonth() + 1; // Months are zero-based, so add 1
        const day = date.getDate();
        const year = date.getFullYear();

        // Format the date as MM/DD/YYYY
        const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

        return (
            <div className='news-block' onClick={handleClick}>
                <div className='news-component'>
                    <div>
                        <img src={process.env.PUBLIC_URL + props.image}/>
                    </div>
                    <div>
                        <h2>{props.title}</h2>
                        <h4>{formattedDate}</h4>
                        <h3>{props.description}</h3>
                    </div>
                </div>
                <div className='divider'/>
            </div>
        )
    }
}

export default NewsPage